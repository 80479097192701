<template>
  <div class="flix-text-center flix-animateme">
    <h4 class="flix-html-h4" :class="'flix-text-' + $getStatusClass(status)">
      {{ $t('message.save', {name: $t('message.' + status)}) }}?
    </h4>
    <div class="flix-form-group" v-if="variables.user && variables.user.email && variables.user.email.trim().toLowerCase() === entry.email.trim().toLowerCase()">
      Wir verschicken <b>keine</b> {{ $t('message.' + status) }} an Ihre Account E-Mail-Adresse <b>{{ variables.user.email }}</b>.
    </div>
    <div class="flix-form-group" v-else>
      Die {{ $t('message.' + status) }} wird in ca. <b>5 Minuten</b> an {{ entry.email }} versendet.
    </div>
    <div class="flix-form-group">
      <a class="flix-btn flix-btn-success flix-btn-xs" href="#" @click.prevent="callback(true)">{{ $t('message.yes') }}</a> <a class="flix-btn flix-btn-danger flix-btn-xs" href="#" @click.prevent="callback(false)">{{ $t('message.no') }}</a>
    </div>
    <small class="flix-html-small">
      <div class="flix-form-group">
        <flixIcon class="flix-text-info" :id="'info-sign'" /> <b>{{ $tc('message.messageTo', 1, {name: $tc('message.customer', 1) + ' #' + entry.booking_id}) }}:</b>&nbsp;
        <span v-if="!note.trim()" class="flix-text-danger">({{ $tc('message.noEntry', 1) }})</span>
      </div>
      <div v-if="note" class="flix-text-success" v-html="$nl2br(note)"></div>
    </small>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    status: String,
    callback: Function,
    entry: Object,
    note: String
  },
  data () {
    return {
      variables: this.$getUserVariables()
    }
  },
  methods: {

  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>

  .flix-animateme
    animation: 2s animateme ease-in-out infinite
    opacity: 0,5

  @keyframes animateme
    from
      opacity: 1
      transform: scale(1)
    50%
      opacity: 0.8
      transform: scale(0.95)
    to
      opacity: 1
      transform: scale(1)
</style>
